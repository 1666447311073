import React, { Fragment } from 'react'
import data from './description.json';
import './description.css';

const {description} = data;



const SiteDescriptionComponent = () => {
    return (
     
        <h4>Welkom op mijn e-portfolio! 👋 <br/>  {description} <br/>  Have fun reading! </h4>
   
    )
}

export default SiteDescriptionComponent

import React from 'react'
import PostHeader from './PostHeader.js'
import PostBody from './Postbody.js'



const PostPage = ({post}) => {
    
    


    return (
        <React.StrictMode>
        <PostHeader title={post.content.title} />
        <PostBody post={post}/>
        </React.StrictMode>
    )
}


export default PostPage

import React from 'react'

import data from "../data.json";
import "./header.scss"
import "./dropdown.scss"
import PostPage from './PostPage.js';


const PostHeader = ({title}) => {


    
    const listPostLinks = data.slides.map((obj) =>
        <li><a href={obj.content.link}> {obj.content.title} </a></li>
    ); 

    function openMenu(e){
        console.log("clicked")
        let menu = document.querySelector(".menu");
        menu.classList.toggle("active");
        let bottom = document.querySelector(".bottom")
        bottom.classList.toggle("active");
    }


    return (
       <div class="topics">
           <div class="slider">


           <h2>{title}</h2>


        </div>
        <button onClick={openMenu}>
        <svg class='bottom' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.64598 4.64604C1.69242 4.59948 1.7476 4.56253 1.80834 4.53733C1.86909 4.51212 1.93421 4.49915 1.99998 4.49915C2.06575 4.49915 2.13087 4.51212 2.19161 4.53733C2.25236 4.56253 2.30753 4.59948 2.35398 4.64604L7.99998 10.293L13.646 4.64604C13.6925 4.59955 13.7477 4.56267 13.8084 4.53752C13.8691 4.51236 13.9342 4.49941 14 4.49941C14.0657 4.49941 14.1308 4.51236 14.1916 4.53752C14.2523 4.56267 14.3075 4.59955 14.354 4.64604C14.4005 4.69253 14.4373 4.74772 14.4625 4.80846C14.4877 4.86919 14.5006 4.9343 14.5006 5.00004C14.5006 5.06578 14.4877 5.13088 14.4625 5.19162C14.4373 5.25236 14.4005 5.30755 14.354 5.35404L8.35398 11.354C8.30753 11.4006 8.25236 11.4375 8.19161 11.4628C8.13087 11.488 8.06575 11.5009 7.99998 11.5009C7.93421 11.5009 7.86909 11.488 7.80834 11.4628C7.7476 11.4375 7.69242 11.4006 7.64598 11.354L1.64598 5.35404C1.59942 5.30759 1.56247 5.25242 1.53727 5.19167C1.51206 5.13093 1.49908 5.06581 1.49908 5.00004C1.49908 4.93427 1.51206 4.86915 1.53727 4.80841C1.56247 4.74766 1.59942 4.69248 1.64598 4.64604V4.64604Z" fill="white"/>
</svg>
</button>
    <nav role="navigation">

    <ul class="menu">
        <li><a href='/'>Home</a></li>
        {listPostLinks}
    </ul>
    </nav>
        </div>
    
    )
}

export default PostHeader

import React from 'react'

import "./body.css"


const PostBody = ({post}) => {
    let content = post.content
    
    return (
        <article>
            <h3>{content.title}</h3>
            <p class="date">{content.date}</p>  
            <p class='postContent'>{content.postContentIntro}</p> <br></br>
            <p class='postContent'>{content.postContentBlock}</p> <br></br>
            <img src={content.postContentPic}></img>
            <p class='postContent'>{content.postContentSlot}</p> <br></br>

        </article>
    )
}

export default PostBody

import React from 'react'

import './pictureFrame.css'

const PictureFrame = () => {
    return (
       <div id='frame'> <a href="https://www.linkedin.com/in/maxim-decherf/">
      
    <img src="https://media-exp1.licdn.com/dms/image/C4E03AQFVAcHIEjx2Ww/profile-displayphoto-shrink_400_400/0/1606059350049?e=1654732800&v=beta&t=q3wJrV0vztCSxmlB_wib2R2XCkhNEY8Z2ItMr7wJELQ"/>  </a> </div>
       
    )
}

export default PictureFrame

import React from 'react'
import  TitleComponent from '../Name/titleComponent.js';
import  SiteDescriptionComponent from '../SiteDescription/SiteDescriptionComponent.js';
import VerticalCarousel from "../Carousel/CarouselComponent.js";
import data from "../data.json";


const Home = () => {
    return (
        <React.StrictMode>
        <header>
        <TitleComponent />
        <SiteDescriptionComponent />
        </header>
        <VerticalCarousel data={data.slides} leadingText={data.leadingText} />
        </React.StrictMode>
    )
}

export default Home

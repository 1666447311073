import React from 'react'

import './post.scss'

const PostComponent = ({ date, title, description, link, img }) => {
    return (
        <div class="blog-slider">
        <div class="blog-slider__wrp swiper-wrapper">
          <div class="blog-slider__item swiper-slide">
            <div class="blog-slider__img">
            <img src={img} alt="Simply Easy Learning" />
            </div>
            <div class="blog-slider__content">
              <p class="date">{date}</p>
              <h3>{title}</h3>
              <div class="blog-slider__text">{description}</div>
              <a href={link} class="blog-slider__button">Lees meer</a>
            </div>
          </div>
          
        </div>
      </div>
    )
}

export default PostComponent

import React from 'react'

import './title.css'

import PictureFrame from "../PictureFrame/pictureFrame.js";


const TitleComponent = () => {
    return (
       <h1> Maxim Decherf <PictureFrame/></h1>
       
    )
}

export default TitleComponent

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './HomePage/Home.js'
import PostPage from './PostPage/PostPage.js';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import data from "./data.json";

const rootElement = document.getElementById("root");




const listPostLinks = data.slides.map((obj) =>
  <Route path={obj.content.link} element={<PostPage post={obj}/>}/>

); 
 


ReactDOM.render(

  <React.StrictMode>


  <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          {listPostLinks}
        </Routes>
  </Router>

  </React.StrictMode>,
  rootElement
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
